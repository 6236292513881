import { Router } from "@reach/router"
import React from "react"

const Profile = props => {
    const { profile = null } = props || {}
    return <>
        <h3>Profile: {profile}</h3>
        {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
}

// Hack fix for crappy reach/router -> https://github.com/reach/router/issues/63
const RouterComponent = props => <>{props.children}</>

const App = () => {
    return <>
        <Router primary={false} component={RouterComponent}>
            <Profile path="/p/:profile" />
        </Router>
    </>
}
export default App